<template>
    <div class="row vh-100 d-flex justify-content-center">
        <div class="col-12 align-self-center">
            <div class="row">
                <div class="col-lg-5 mx-auto">
                    <div class="card">
                        <div class="card-body p-3">

                            <form @submit.prevent="submitForm" class="form-horizontal auth-form">

                                <div class="form-group mb-2">
                                    <label for="name">Name</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" required id="name" placeholder="Enter your name" v-model="name">
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label for="email">Email</label>
                                    <div class="input-group">
                                        <input type="email" class="form-control" required id="email" placeholder="Enter email" v-model="email">
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label for="mobile">Mobile</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control" required id="mobile" placeholder="Enter 10 digit mobile number" v-model="mobile">
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label for="password">Password</label>
                                    <div class="input-group">
                                        <input type="password" class="form-control" required id="password" placeholder="Enter password" v-model="password">
                                    </div>
                                </div>


                                <div class="form-group mb-0 row">
                                    <div class="col-12" v-if="!processing">
                                        <button class="btn btn-primary btn-block waves-effect waves-light" type="submit">Sign up <i class="fas fa-sign-in-alt ml-1"></i></button>
                                    </div>
                                    <div class="col-12" v-else>
                                        <button class="btn btn-primary btn-block waves-effect waves-light" type="button">Processing...</button>
                                    </div>
                                </div>
                            </form>
                            <div class="m-3 text-center text-muted">
                                <p class="mb-0">Already have an account ?  <router-link :to="{name:'login'}" href="auth-register.html" class="text-primary ml-2">Login Now.</router-link></p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
    data(){
        return{
            name:"", email:"", mobile:"", password:"", processing: false,
        }
    },
    methods:{
        submitForm(){
            this.processing = true
            this.$user.registerUser(this.name, this.email, this.mobile, this.password).then((res)=>{
                this.processing = false
                if(res.data.success){
                    this.$swal("Your registration is successful. Please login to continue");
                }else{
                    this.$swal("Either mobile number or email already exist. Please try to login.");
                }

                this.$router.push({name:"login"});
            })
        }
    }
}
</script>

<style scoped>

</style>